export const passwords = [
    'sophiejune',
    'smbsd-trial',
    'madison2024',
    'fairlawn2024',
    'alvin2024',
    'adam2024',
    'heartlandtrial',
];

export default {};
